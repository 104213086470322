import type { RootLoaderData } from "~/loader.root.server.ts";
import { useTurboStreamRouteLoaderData } from "~/utils.turbostream.ts";

export const useRootLoaderData = () => {
	const { root, ENV, salesManagers, salesConsultants, globalNotifications } =
		useTurboStreamRouteLoaderData<RootLoaderData>("root");

	const { authenticatedUser, dealership, stringTemplate, zipDealVersion } =
		root;

	return {
		authenticatedUser: authenticatedUser,
		dealership,
		user: authenticatedUser?.user,
		ENV,
		salesManagers,
		salesConsultants,
		globalNotifications,
		stringTemplate: stringTemplate || {},
		zipDealVersion,
	};
};
